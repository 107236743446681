// components/AutoPlaySwiper.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay,Navigation } from 'swiper/modules';

const OurServices = () => {
    const serviceSlider= {
        loop: true,
        speed: 1000,
        autoplay:{
          delay: 2500,
          disableOnInteraction: false,
        },
        slidesPerView: 3,
        spaceBetween: 30,
    
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            320: {
                slidesPerView: 1,
            },
            576: {
                slidesPerView: 2,
            },
            992: {
                slidesPerView: 3,
            }
        }
      }
  return (
    <section className={"services-area sec-mar"}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={"sec-title"}>
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p style={{textAlign:'justify'}}>
              At Codeits, we offer a comprehensive suite of services designed to meet the unique needs of your business. Our expertise spans a wide range of technologies and solutions, ensuring that we can support you at every stage of your digital journey.
              </p>
            </div>
          </div>
                <Swiper  {...serviceSlider} className="swiper services-slider"
                modules={[Autoplay,Navigation]}
                spaceBetween={30}
                slidesPerView={1}
                autoplay={{
                    delay: 3000, // 3 seconds
                    disableOnInteraction: false,
                }}
                loop={true}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
                >
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide1.jpg" alt="Slide 1" />
                </SwiperSlide>
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide2.jpg" alt="Slide 2" />
                </SwiperSlide>
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide3.jpg" alt="Slide 3" />
                </SwiperSlide>
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide3.jpg" alt="Slide 3" />
                </SwiperSlide>
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide3.jpg" alt="Slide 3" />
                </SwiperSlide>
                <SwiperSlide
                    className="swiper-slide wow animate fadeInUp"
                    data-wow-delay="200ms"
                    data-wow-duration="1500ms">
                    <img src="/images/slide3.jpg" alt="Slide 3" />
                </SwiperSlide>
                {/* Add more slides as needed */}
                </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
  );
};

export default OurServices;
