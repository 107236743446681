"use client"

import Link from "next/link";
import React from "react";

import Logo from "../assets/img/logo.svg";

function Footer(props) {
  const scrollTop=()=> {
    // if(typeof window != 'undefined'){
    //   window.scrollTo({top:0,behavior:"smooth"})
    // }
  }
  return (
    <>
      <footer className={props.footerAddclass} >
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link  href={"/"}>
                    <Logo width={180} style={{marginLeft:10,color:'#fff'}}/>
                    </Link>
                  </div>
                  <p>
                  {"Turning your ideas into reality. Let's build the future together."}
                  </p>
                  <ul className="social-media-icons">
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/">
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/">
                        <i className="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Our Services</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link href={`/service-details`} onClick={scrollTop}>Strategy &amp; Research</Link>
                    </li>
                    <li>
                      <Link href={`/service-details`} onClick={scrollTop}>Web Development</Link>
                    </li>
                    <li>
                      <Link href={`/service-details`} onClick={scrollTop}>Web Solution</Link>
                    </li>
                    <li>
                      <Link href={`/service-details`} onClick={scrollTop}>App Development</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Quick Links</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link href={`/about`} onClick={scrollTop}>About Us</Link>
                    </li>
                    <li>
                      <Link href={`/service`} onClick={scrollTop}>Services</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Contacts</h4>
                  <div className="number">
                    <div className="num-icon">
                    <i className="fab fa-whatsapp" ></i>
                    </div>
                    <div className="phone">
                      <a href="tel:+56973923591">+56 9 7392 3591</a>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope" />
                    </div>
                    <div className="email">
                      <a href="mailto:contacto@codeits.cl">contacto@codeits.cl</a>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <p>Antonio Bellet 193 OF 302 3P, Providencia, Santiago</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright {new Date().getFullYear()} <b>Codeits</b> | You Imagine{" "}
                    <Link href={"#"}>We make it possible</Link>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  <li>
                    <Link href={"#"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link href={"#"}>Terms of Use</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
