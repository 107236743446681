

import Link from "next/link";
import React, { useEffect, useReducer, useRef, useState } from "react";
//import WOW from "wowjs";
import Logo from '@/app/assets/img/logo.svg';


function Header() {

  const [sidebar, setSidebar] = useState(false);
  const [isSticky, setSticky] = useState(false);
  const ref = useRef(null);


  /*---------header top Sticky event----------*/
  const handleStickyHeader = () => {
    if (ref.current) {
      //setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

 
  /*---------menu button event----------*/
  const handleSidbarMenu = () => {
    if (sidebar === false || sidebar === 0) {
      setSidebar(1);
    } else {
      setSidebar(false);
    }
  };

/*---------add event scroll top----------*/
  const scrollTop=()=> {
    // if(typeof window != 'undefined'){
    //   window.scrollTo({top:0,behavior:"smooth"})
    // }
  }

  /*---------Using reducer mange the active or inactive menu----------*/
  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);


  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "service":
        return { activeMenu: "service" };
      case "project":
        return { activeMenu: "project" };
      case "blog":
        return { activeMenu: "blog" };
      case "page":
        return { activeMenu: "page" };
      default:
        return { activeMenu: "" };
    }
  }
  useEffect(() => {
    // new WOW.WOW({
    //   live: false,
    // }).init();
    
    // if(typeof window != 'undefined'){
    //   window.addEventListener("scroll", handleStickyHeader);
    // }
    // return () => {
    //   if(typeof window != 'undefined'){
    //     window.addEventListener("scroll", handleStickyHeader);
    //   }
    // };
  }, []);


  return (
    <>
      <header
        className={`${"header-area position_top"} ${isSticky ? "sticky" : ""}`}
        ref={ref}
      >
        <div className="site-logo">
          <div className="logo">
            <Link href={"/"} onClick={scrollTop}>
              {/* <img
                src={ "/assets/img/logo.svg"}
                alt="logo-img"
              /> */}
              <Logo width={180} style={{marginLeft:10,color:'#fff'}}/>
            </Link>
          </div>
        </div>
        <div className="main-menu">
          <nav className={sidebar === 1 ? "main-nav slidenav" : "main-nav"}>
            <div className="mobile-menu-logo">
              {/* <Link href={"/"} onClick={scrollTop}>
                <img
                  src={process.env.PUBLIC_URL + "img/logo-dark.svg"}
                  alt="images"
                />
              </Link> */}
              <div className="remove" onClick={handleSidbarMenu}>
                <i className="bi bi-plus-lg" />
              </div>
            </div>
            <ul>
              <li
                className="has-child active"
                onClick={() => dispatch({ type: "homeOne" })}
              >
                  <Link
                    href={"#"}
                    className={state.activeMenu === "homeOne" ? "active" : ""}
                  >
                    Home
                  </Link>
                  {/* <i className="bi bi-chevron-down" />
                  <ul
                    className={
                      state.activeMenu === "homeOne"
                        ? "sub-menu d-block"
                        : "sub-menu d-none"
                    }
                  >
                    <li>
                      <Link
                        href={"/"}
                        onClick={scrollTop}
                        className={state.activeMenu === "homeOne" ? "active" : ""}
                      >
                        Home 01
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/index2`}
                        onClick={scrollTop}
                      >
                        Home 02
                      </Link>
                    </li>
                  </ul> */}
              </li>
              <li>
                <Link
                  href={`page/home/AboutArea`}
                  onClick={scrollTop}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link href={"#"} onClick={() => dispatch({ type: "service" })}>
                  Our Services
                </Link>
                {/* <i className="bi bi-chevron-down" />
                <ul
                  className={
                    state.activeMenu === "service"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <Link
                      href={`/service`}
                      onClick={scrollTop}
                    >
                      Service
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/service-details`}
                      onClick={scrollTop}
                    >
                      Service Details
                    </Link>
                  </li>
                </ul> */}
              </li>
              {/* <li>
                <Link href={"#"} onClick={() => dispatch({ type: "project" })}>
                  Project
                </Link>
                <i className="bi bi-chevron-down" />
                <ul
                  className={
                    state.activeMenu === "project"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <Link
                      href={`/project`}
                      onClick={scrollTop}
                    >
                      Project
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/project-details`}
                      onClick={scrollTop}
                    >
                      Project Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-child">
                <Link href={"#"} onClick={() => dispatch({ type: "blog" })}>
                  Blog
                </Link>
                <i className="bi bi-chevron-down" />
                <ul
                  className={
                    state.activeMenu === "blog"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <Link
                      href={`/blog`}
                      onClick={scrollTop}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/blog-standard`}
                      onClick={scrollTop}
                    >
                      Blog standard
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/blog-details`}
                      onClick={scrollTop}
                    >
                      Blog Details
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="has-child">
                <Link href={"#"} onClick={() => dispatch({ type: "page" })}>
                  Pages
                </Link>
                <i className="bi bi-chevron-down" />
                <ul
                  className={
                    state.activeMenu === "page"
                      ? "sub-menu d-block"
                      : "sub-menu d-none"
                  }
                >
                  <li>
                    <Link
                      href={`/team`}
                      onClick={scrollTop}
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/pricing`}
                      onClick={scrollTop}
                    >
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/faq`}
                      onClick={scrollTop}
                    >
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link
                      href={`/error`}
                      onClick={scrollTop}
                    >
                      Error 404
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  href={`/contact`}
                  onClick={scrollTop}
                >
                  Contact Us
                </Link>
              </li> */}
              {/* fake li to fill the space */}
              <li style={{width: "100%"}}></li>
            </ul>
            {/* <div className="get-qoute d-flex justify-content-center d-lg-none d-block pt-50">
                    <div className="cmn-btn">
                        <div className="line-1"></div>
                        <div className="line-2"></div>
                        <Link href={`/contact`} onClick={scrollTop}>Get A Quote</Link>
                    </div>
            </div> */}
          </nav>
        </div>
        
        <div className="nav-right">
          <div className="get-qoute">
            <div className="cmn-btn">
              <div className="line-1" />
              <div className="line-2" />
              {/* <Link
                href={`/contact`}
                onClick={scrollTop}
              >
                Get A Quote
              </Link> */}
            </div>
          </div>
          <div className="mobile-menu">
            <Link href="#" onClick={handleSidbarMenu} className={"cross-btn"}>
              <span className="cross-top" />
              <span className="cross-middle" />
              <span className="cross-bottom" />
            </Link>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
