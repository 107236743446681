import Link from 'next/link'
import React from 'react'

function HeroArea() {
  const scrollTop=()=> {
    // if(typeof window != 'undefined'){
    //   window.scrollTo({top:0,behavior:"smooth"})
    // }
  }
  return (
    <>
     <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src={"/assets/img/hero4.jpg"} alt="HeroBanner" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li><a href="#">Facebook</a></li>
            <li><a href="#">Instagram</a></li>
            <li><a href="#">Linkedin</a></li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>You Imagine <span style={{fontSize:30}}>We make it Possible.</span></h1>
                  <p>{"At Codeits, we believe in the power of imagination. Your ideas are the seeds of innovation, and we're here to nurture them into reality. Whether you're dreaming up the next big app, envisioning a sleek new website, or planning a digital transformation for your business, we have the expertise and passion to make it possible."}</p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <Link href={`/about`} onClick={scrollTop}>About Us</Link>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      {/* <Link href={`/project`} onClick={scrollTop}>See Project</Link> */}
                      <Link href="mailto:contacto@codeits.cl">Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6" />
            </div>
          </div>
        </div>
      </section>   
    </>
  )
}

export default HeroArea