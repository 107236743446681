"use client"
import Image from "next/image";
import { useEffect, useState } from "react";
import Preloader from "./layout/Preloader";
import Header from "./common/Header";
import Footer from "./common/Footer";
import HomePageOne from "./page/home/HomePageOne";

export default function Home() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  console.log(process)

  if(loading) {
    return <Preloader />
  }

  return (
        <>
          <Header />
          <HomePageOne />
          <Footer />
        </>
  );
}
