import React, { useEffect, useState } from "react";
import Link from "next/link";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Autoplay, Navigation} from 'swiper/modules';

function ServiceArea(props) {
  const [isFirstLoop, setIsFirstLoop] = useState(true);
  const scrollTop=()=> {
    // if(typeof window != 'undefined'){
    //   window.scrollTo({top:0,behavior:"smooth"})
    // }
  }
  const serviceSlider= {
    loop: true,
    speed: 1000,
    autoplay:{
      delay: 3500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    breakpoints: {
        320: {
            slidesPerView: 1,
        },
        576: {
            slidesPerView: 2,
        },
        992: {
            slidesPerView: 3,
        }
    }
  }
  
  // Effect to update state after the first loop
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsFirstLoop(false);
    }, 3000 * (3 + 1)); // Adjust based on your delay and number of slides (3 slides example)

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <section className={`${"services-area sec-mar"} `}>
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} `}>
              <span>Our Solutions</span>
              <h2>Services</h2>
              <p style={{textAlign:'justify'}}>
              At Codeits, we offer a comprehensive suite of services designed to meet the unique needs of your business. Our expertise spans a wide range of technologies and solutions, ensuring that we can support you at every stage of your digital journey.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider" 
                modules={[Autoplay,Navigation]}
                autoplay={{
                    delay: 5000, // 3 seconds
                    disableOnInteraction: false,
                }}
                loop={true}
                >
            <div className="swiper-wrapper">
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="single-service">
                  <span>01</span>
                  <div className="icon">
                    <img src={ "/assets/img/icons/service-icon-5.png" } alt="service-icon" />
                  </div>
                  <h4>Custom-Made Software</h4>
                  <p>
                  Your business is one of a kind, and your software should be too. We specialize in creating bespoke software solutions tailored to your specific requirements.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} `}>
                  <span>02</span>
                  <div className="icon">
                    <img src={ "/assets/img/icons/service-icon-2.png"  } alt="service-icon" />
                  </div>
                  <h4>Mobile App Development</h4>
                  <p>
                  {"In today's mobile-first world, having a strong presence on Android and iOS is crucial. We design and develop intuitive, feature-rich mobile apps that engage users and drive business growth."}
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                
                <div className={`${"single-service"} `}>
                  <span>03</span>
                  <div className="icon">
                    <img src={"/assets/img/icons/cloud.svg"} alt="service-icon" />
                  </div>
                  <h4>Migrations to the Cloud </h4>
                  <p>
                  Moving to the cloud can be a game-changer for your business, offering improved scalability, cost-efficiency, and resilience. Our cloud migration services ensure a seamless transition, minimizing downtime and optimizing your infrastructure for performance and security.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                
                <div className={`${"single-service"} `}>
                  <span>04</span>
                  <div className="icon">
                    <img src={"/assets/img/icons/micro.svg"} alt="service-icon" />
                  </div>
                  <h4>Monolithic to Microservices Transition </h4>
                  <p>
                  As your business grows, so does the complexity of your software. We help you modernize your architecture by transitioning from monolithic systems to microservices.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                
                <div className={`${"single-service"} `}>
                  <span>05</span>
                  <div className="icon">
                    <img src={"/assets/img/icons/feature-icon-1.png"} alt="service-icon" />
                  </div>
                  <h4>B2B Integration</h4>
                  <p>
                  Efficient and secure integration between businesses is essential in today’s interconnected world. We facilitate seamless B2B integration.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                
                <div className={`${"single-service"} `}>
                  <span>06</span>
                  <div className="icon">
                    <img src={"/assets/img/icons/leg.svg"} alt="service-icon" />
                  </div>
                  <h4>Legacy Software</h4>
                  <p>
                  Outdated software can slow down your operations and hold your business back. We breathe new life into your legacy systems.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className={`swiper-slide ${isFirstLoop ? ' wow animate fadeInUp' : ''}`}
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                
                <div className={`${"single-service"} `}>
                  <span>07</span>
                  <div className="icon">
                    <img src={"/assets/img/icons/kafka.svg"} alt="service-icon" />
                  </div>
                  <h4>Kafka-Based Solutions</h4>
                  <p>
                  Handling large volumes of data requires robust and scalable solutions. We implement Kafka-based systems that process long streams of data efficiently and reliably.
                  </p>
                  <div className="read-btn">
                    <Link
                      href={`page/home/service-details`}
                      onClick={scrollTop}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
